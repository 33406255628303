// --- GLOBAL VARIABLES --- //
var $window = $(window)
var $document = $(document)
var $header = $('.header')
var bpSmall = 771
var bpMedium = 992
var bpLarge = 1200
var classIsActive = 'is-active'
var classIsDisabled = 'is-disabled'
var classIsLast = 'is-last'
var classHasError = 'has-error'
var classIsLoading = 'is-loading'
var classIsOpened = 'is-opened'
var classIsSelected = 'is-selected'
var classIsCollapse = 'is-collapse'
var classIsAnimated = 'is-animated'
var $this = null
var $containers = $('html,body')

// --- COMPONENTS, PARTS --- //
$(document).ready(function () {
	// ---  COMPONENTS --- //

	// paralax
	componentParallax()

  // Validate forms
  componentValidateForms()

  // Scroll to
  partScrollTo()

})

// --- LOAD EVENT --- //
$(window).bind('load', function () {
  // Page Loader
  $('body').addClass(classIsActive)
})
